<template>
  <b-card
    v-if="editing"
    title=""
    class="mb-2"
    ref="edit-education"
    id="edit-education"
    hide-footer

  >
    <div class="education_editor">

      <b-form @submit.prevent="updateEducation" class="mt-8">

        <b-form-group id="input-group-education_id" label="ID" label-for="input-education_id">
          <b-form-input
            id="input-education_id"
            v-model="local_education.id"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-faculty_id" :label="$t('PAGES.EDUCATIONS.FACULTY')" label-for="input-faculty_id">
          <FacultyList
            :faculty_id="local_education.faculty_id"
            v-on:update:faculty_id="updated_faculty_id"
          />
        </b-form-group>

        <b-form-group id="input-group-company_id" :label="$t('PAGES.EDUCATIONS.SECTION_COMPANY')" label-for="input-company_id">
          <CompanyList
            :company_id="local_education.company_id"
            v-on:update:company_id="updated_company_id"
          />
        </b-form-group>

        <b-form-group
          id="type"
          :label="$t('PAGES.EDUCATIONS.TYPE')"
          label-for="type"
          class="mt-8"
          :state="validType"
        >
          <b-form-select v-model="local_education.type" :options="typeOptions"></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-code" :label="$t('PAGES.EDUCATIONS.CODE')" label-for="input-code">
          <b-form-input
            id="input-code"
            v-model="local_education.code"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-program_code" :label="$t('PAGES.EDUCATIONS.PROGRAM_CODE')" label-for="input-program_code">
          <b-form-input
            id="input-program_code"
            v-model="local_education.program_code"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-name" :label="$t('PAGES.EDUCATIONS.NAME')" label-for="input-name">
          <b-form-input
            id="input-name"
            v-model="local_education.name"
            type="text"
          ></b-form-input>
        </b-form-group>


        <div v-if="(errors.length > 0)" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">

              <ul>
                <li v-for="(error) in errors">
                  <div v-if="error === 'company'">{{$t('PAGES.EDUCATIONS.ERROR_COMPANY')}}</div>
                  <div v-if="error === 'faculty'">{{$t('PAGES.EDUCATIONS.ERROR_FACULTY')}}</div>
                  <div v-if="error === 'type'">{{$t('PAGES.EDUCATIONS.ERROR_TYPE')}}</div>
                  <div v-if="error === 'code'">{{$t('PAGES.EDUCATIONS.ERROR_CODE')}}</div>
                  <div v-if="error === 'name'">{{$t('PAGES.EDUCATIONS.ERROR_NAME')}}</div>
                </li>
              </ul>

            </div>
        </div>


        <b-button type="submit" variant="primary" class="mt-8">{{$t('COMMON.SAVE')}}</b-button>

      </b-form>
    </div>
  </b-card>
</template>


<script>
import 'vue-select/dist/vue-select.css';
import Select2 from 'v-select2-component';

import axios from 'axios';
import { mapGetters } from 'vuex';
import FacultyList from '@/view/pages/ml/education/FacultyList.vue';
import CompanyList from '@/view/pages/ml/education/CompanyList.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'education-editor',
  mixins: [ toasts ],
  components: {
    Select2,
    FacultyList,
    CompanyList
  },
  emits: ['update:education', 'created'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only']),
    validSection() {
      return this.local_education.company_id;
    },
    validType() {
      return this.local_education.type;
    }
  },
  mounted() {
    if (this.education) {
      this.local_education = { ...this.education }
    }
  },
  data() {
    return {
      local_education: {},
      errors: [],

      typeOptions: [
        { value: 'NONE', text: this.$t('PAGES.EDUCATIONS.TYPE_NONE') },
        { value: 'PROGRAM', text:  this.$t('PAGES.EDUCATIONS.TYPE_PROGRAM') },
        { value: 'COURSE', text:  this.$t('PAGES.EDUCATIONS.TYPE_COURSE') },
        { value: 'FREE_COURSE', text:  this.$t('PAGES.EDUCATIONS.TYPE_FREE_COURSE') },
      ],
      creating: false,
      editing: false,
    };
  },
  props: ['education'],
  watch: {
    education: {
      deep: true,
      handler(val) {
        this.local_education = { ...val }
      }
    },
    'local_education.type'() { this.calcErrors() },
    'local_education.company_id'() { this.calcErrors() },
    'local_education.faculty_id'() { this.calcErrors() },
    'local_education.name'() { this.calcErrors() },
    'local_education.code'() { this.calcErrors() },

  },
  methods: {

    calcErrors() {
      this.errors = [];

      if (!this.local_education.type || this.local_education.type === 'NONE') {
        this.errors.push('type');
      }

      if (!this.local_education.company_id || this.local_education.company_id === '') {
        this.errors.push('company');
      }

      if (!this.local_education.faculty_id || this.local_education.faculty_id === '') {
        this.errors.push('faculty');
      }

      if (!this.local_education.code || this.local_education.code === '' || this.local_education.code.length < 4) {
        this.errors.push('code');
      }
    },

    edit() {
      this.editing = true;
    },

    create() {
      this.creating = true;
      this.editing = true;
    },

    updated_faculty_id(faculty_id) {
      this.local_education.faculty_id = faculty_id;
    },

    updated_company_id(company_id) {
      this.local_education.company_id = company_id;
    },

    updateEducation(e) {
      if (e !== null) {
        e.preventDefault();
      }


      if (this.creating) {
        const loader = this.$loading.show();

        axios
          .post('/education', this.local_education)
          .then(res => {
            loader && loader.hide();
            if (res.status === 201) {
              this.creating = false;
              this.local_education = res.data;

              this.$emit('created', this.local_education);

              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EDUCATIONS.CREATED'));
            }
            else if (res.status === 409) {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.ALREADY_EXISTS'));
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_CREATE'));
            }
          })
          .catch(err => {
            loader && loader.hide();
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_CREATE'));
          });
      } else {

        this.$emit('update:education', this.local_education);

      }
    },
    resetForm() {

    },

  }
};
</script>
